<template>
	<div class="full-height flex-column">
		<div class="header">
			<div class="top top_mafi f-left">
				<div class="util">
					<button
						@click="$emit('goBack')"
						class="arrow_white"
					>
						<i class="icon-arrow-left"></i>
					</button>
				</div>
				<h2 class="page_tit">{{ program.title }}</h2>
			</div>
			<div class="save">
				<button
					@click="doType"
					class="btn_save"
				>{{  text_type_btn }}</button>
			</div>
		</div>

		<div class="subindex_item full-height">
			<div class="container full-height">
				<div class="mt-50 subindex_box_1 full-height overflow-y-auto">

					<ul
						v-if="items.length > 0"
						class="settings_list"
					>
						<template
							v-if="type == 'view'"
						>
							<template
								v-for="(item, index) in items"
							>
								<li
									:key="'item_' + index"
									class="settings_list_item"
								>
									<button
									>
										<div class="cell_area">
											<div class="cell_tit">{{  item.board_name }}</div>
										</div>
									</button>
								</li>
							</template>
						</template>

						<draggable
							v-if="type == 'modify'"
							v-model="items"
							@start="setAction"
							@end="is_action = ''"
							handle=".handle"
						>
							<template
								v-for="(item, index) in items"
							>
								<li
									:key="'item_' + index"
									class="settings_list_item "
									:class="{ order_action: is_action === index}"
								>
									<div class="cell_area">
										<div class="cell_tit_area">
											<button
												@click="onConfirm(item)"
												class="btn_cell-del"
											><span class="hide">{{  $language.common.delete }}</span></button>
											<span class="cell_tit">{{  item.board_name  }}</span>
											<button
												@click="toModify(item)"
												class="btn_cell-edit btn_s btn_outline_blue"
											>{{  $language.word.modify }}</button>
										</div>
										<!-- 순서이동 액션탭 -->
										<div class="cell_action handle">
											<span class="btn_order"><em class="hide">{{  $language.common.move_order }}</em></span>
										</div>
										<!-- 순서이동 액션탭 -->
									</div>
								</li>
							</template>
						</draggable>
					</ul>
					<Empty
						v-else
					>
					</Empty>
				</div>
			</div>
		</div>
		<div
			class="mt-auto btn_area pa-20"
		>
			<button
				@click="toAdd"
				class="btn_l btn_fill_blue"
			>{{  $language.notice.add }}</button>
		</div>



		<popup_confirm
			v-if="is_confirm"
			@click="deleteBoard(true)"
			@cancel="is_confirm = false"
			:btn_click="$language.common.delete"
		>
			<template v-slot:title>{{  $language.voting.title_voting_board_delete }}</template>
			<template
				v-slot:main-txt
			>{{  $language.voting.txt_voting_board_delete }} </template>
			<template
				v-slot:sub-txt
			>{{  $language.voting.txt_voting_board_delete2 }}</template>
		</popup_confirm>
	</div>
</template>

<script>

	import draggable from 'vuedraggable'
	import Empty from "@/view/Layout/Empty";
	import popup_confirm from "@/view/Layout/PopupConfirm";

	export default {
		name: 'CartelVotingBoardList'
		, components: {popup_confirm, Empty, draggable}
		, props: ['user']
		, data: function(){
			return {
				program: {
					title: this.$language.voting.title_voting_board_manage
					, name: this.$language.voting.title_voting_board_manage
					, type: 'cartel_sub'
					, not_header: true
					, not_footer: true
				}
				, type: 'view'
				, items: []
				, is_action: false
				, is_confirm: false
				, item_confirm: {

				}
			}
		}
		, computed: {
			text_type_btn: function(){
				let t = ''
				switch (this.type){
					case 'view':
						t = this.$language.word.modify
						break
					case 'modify':
						t = this.$language.common.complete
						break
				}
				return t
			}
			, cartl_vote_board_list: function(){
				let t = [];
				let i = 1;
				this.items.filter((item) => {
					t.push({
						board_number: item.board_number
						, board_sort_seqen: i
					})
					i++
				})
				return t
			}
		}
		, methods: {
			doType: function(){
				switch (this.type){
					case 'view':
						this.type = 'modify'
						break
					case 'modify':
						this.postBoardSort()
						break
				}
			}
			, getData: async function(){
				try{
					this.$bus.$emit('on', true)

					let url = this.$api_url.api_path.get_open_vote_list
					if(this.user.member_number){
						url = this.$api_url.api_path.get_voting_board_list
					}
					const result = await this.$Request({
						method: 'post'
						, url: url
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})
					if(result.success){
						this.items = result.data.cartl_vote_board_list
						console.log(this.items)
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, toAdd: function(){
				this.$bus.$emit('to', { name: 'CartelVotingBoardAdd', params: { idx: this.$route.params.idx}})
			}
			, setAction: function ($e){
				console.log('e', $e)
				this.is_action = $e.oldIndex
			}
			, deleteBoard: async function(){
				try{
					this.$bus.$emit('on', true)
					let url = this.$api_url.api_path.delete_voting_board

					const result = await this.$Request({
						method: 'post'
						, url: url
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_vote_board_number: this.item_confirm.board_number
						}
						, type: true
					})
					if(result.success){
						await this.getData()
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
					this.is_confirm = false
				}
			}
			, onConfirm: function(item){
				this.item_confirm = item
				this.is_confirm = true
			}
			, toModify: function(item){

				this.$bus.$emit('to', { name: 'CartelVotingBoardAdd', params: { idx: this.$route.params.idx, v_id: item.board_number}})
			}
			, postBoardSort: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.put_voting_board_list
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_vote_board_list: this.cartl_vote_board_list
						}
						, type: true
					})
					if(result.success){
						await this.getData()
						this.type = 'view'
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
					this.is_confirm = false
				}
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.getData()
		}
	}
</script>